import * as React from "react";

import Header from "../components/Header";

const GettingStarted = () => {
  return (
    <div className="bg-devhook-200">
      <Header />
      <div className="mx-auto md:px-12 lg:pt-0 pt-8 max-w-screen-xl min-h-screen justify-center flex text-center"></div>
    </div>
  );
};

export default GettingStarted;
